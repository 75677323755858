import { Table } from 'react-bootstrap';
import Accordion from './Accordion';
import CustomCarousel from './CustomCarousel'; 
import { Helmet } from 'react-helmet';
import './smallkey.css';
import FareCard from './FareCard';
// import  Table  from './pages/Table';
// import Table from './pages/Table.js';
function  Mahabaleshwar(){

  const cardData = 
    {
      keyword: 'Pune to Mahabaleshwar Tempo Traveller',
      heading: 'Amruta Travels: Tempo Traveller on Rent for Pune to Mahabaleshwar',

      headingDescription: ' Amruta Travels offers the perfect Tempo Traveller rental solutions to make your journey comfortable and enjoyable. Discover the top features and services we provide for your Pune to Mahabaleshwar travel needs.',

      top:"Top Features of Our Tempo Travellers: ",
      topPlaces : [
        {
          "title": "1. Spacious Interiors",
          "description": "Enjoy the generous interior space of our Tempo Travellers, which ensures ample legroom and headroom for all passengers. Whether you're traveling with family or a larger group, our vehicles provide a comfortable environment where everyone can relax and enjoy the journey."
        },
        {
          "title": "2. Luxurious Seating",
          "description": "Travel in style with our plush, reclining seats. Each seat is equipped with individual armrests and adjustable headrests, ensuring maximum comfort during both short and long trips."
        },
        {
          "title": "3. Powerful Air Conditioning",
          "description": "Beat the Pune heat with our advanced air conditioning systems. Our Tempo Travellers are designed to keep the interior cool and comfortable, making your journey pleasant no matter the weather outside."
        },
        {
          "title": "4. Ample Luggage Space",
          "description": "Pack all your essentials without worry. Our Tempo Travellers come with substantial luggage compartments that accommodate your bags and belongings securely, leaving ample room for passengers."
        },
        {
          "title": "5. Modern Entertainment Systems",
          "description": "Enhance your travel experience with our cutting-edge entertainment options. Each Tempo Traveller features music players and LED screens, providing entertainment throughout your trip, whether you’re enjoying your favorite music or a movie."
        },
        {
          "title": "6. Experienced and Professional Drivers",
          "description": "Safety and professionalism are our priorities. Our experienced drivers are well-versed with Pune’s routes and beyond, ensuring a smooth and safe journey while you sit back and enjoy the ride."
        },
        {
          "title": "7. Well-Maintained Vehicles",
          "description": "Our Tempo Travellers are regularly serviced and maintained to the highest standards. We ensure that each vehicle is in top condition, providing you with a reliable and clean travel experience."
        },
        {
          "title": "8. Flexible Rental Options",
          "description": "We offer flexible rental packages to suit your specific needs. Whether it’s a short day trip or a longer journey, you can choose a rental option that best fits your schedule and budget."
        },
        {
          "title": "9. Competitive Pricing",
          "description": "Get the best value for your money with our transparent pricing. We offer clear, upfront rates with no hidden charges, making it easy to plan your travel expenses without surprises."
        },
        {
          "title": "10. 24/7 Customer Support",
          "description": "Our dedicated customer support team is available around the clock to assist you with bookings, inquiries, and any travel-related concerns. We are committed to providing a hassle-free experience from start to finish."
        }
      ],
      services : [
        {
          "name": "Tempo Traveller from Pune to Mahabaleshwar:",
          "description": "Experience a comfortable and stress-free journey from Pune to Mahabaleshwar with our spacious Tempo Travellers, equipped with modern amenities like air conditioning, cushioned seating, and ample legroom, ensuring a relaxing trip for you and your group."
        },
        {
          "name": "Pune to Mahabaleshwar Tempo Traveller Hire:",
          "description": "Get convenient and flexible rental options with our Tempo Travellers for your trip from Pune to Mahabaleshwar. Whether it’s a short vacation or a group outing, we offer multiple vehicle types to suit your requirements."
        },
        {
          "name": "Pune to Panchgani Tempo Traveller:",
          "description": "Travel in comfort and style to Panchgani with our reliable Tempo Traveller services. Perfect for a group day trip or weekend escape, enjoy scenic views and a comfortable ride throughout your journey."
        },
        {
          "name": "Tempo Traveller for Rent in Pune:",
          "description": "Select from our fleet of Tempo Travellers available for rent in Pune, perfect for group trips. Whether you're heading for a local excursion or an outstation tour, we have the right vehicle to match your group size and travel needs."
        },
        {
          "name": "17-Seater Tempo Traveller for Mahabaleshwar Trip:",
          "description": "Our 17-seater Tempo Travellers are designed for medium to large groups traveling to Mahabaleshwar, offering spacious seating, ample luggage space, and air conditioning for a comfortable and enjoyable journey."
        },
        {
          "name": "17-Seater Tempo Traveller for Mahabaleshwar Tour Package:",
          "description": "Book a tour package to Mahabaleshwar in our 17-seater Tempo Traveller, which includes features like cushioned seats, entertainment options, and space for luggage. Travel in comfort while we take care of your transportation needs."
        },
        {
          "name": "Pune Airport to Mahabaleshwar Tempo Traveller on Rent:",
          "description": "Skip the hassle of arranging transportation from the airport. Our Tempo Traveller service picks you up directly from Pune Airport and takes you to Mahabaleshwar, ensuring a comfortable and stress-free start to your vacation."
        },
        {
          "name": "Pune to Tapola Tempo Traveller on Rent:",
          "description": "Explore the scenic beauty of Tapola with our dependable Tempo Traveller rental service. Perfect for families or groups, you can travel comfortably while taking in the breathtaking views."
        },
        {
          "name": "14-Seater Tempo Traveller for Maharashtra Trip:",
          "description": "For smaller groups traveling across Maharashtra, our 14-seater Tempo Travellers offer the ideal combination of comfort and convenience. Visit different attractions around the state with ease."
        },
        {
          "name": "Tempo Traveller on Rent for Mahabaleshwar Outings:",
          "description": "Enjoy the best of Mahabaleshwar with our Tempo Traveller rentals, perfect for local sightseeing tours and group outings. Our well-maintained vehicles ensure a smooth and pleasant experience as you explore the hill station."
        },
        {
          "name": "Mini Bus on Rent for Mahabaleshwar Trip:",
          "description": "For larger groups visiting Mahabaleshwar, our mini buses are the perfect option. These vehicles provide extra seating, comfort, and luggage space for a convenient and enjoyable journey."
        },
        {
          "name": "Tempo Traveller on Rent in Pune Rate Per Km:",
          "description": "Our Tempo Traveller rental service in Pune offers clear and transparent pricing, calculated per kilometer. Plan your trips with confidence, knowing there are no hidden costs."
        },
        {
          "name": "Pune to Mahabaleshwar Tempo Traveller Price:",
          "description": "We offer competitive rates for our Tempo Traveller services from Pune to Mahabaleshwar, making group travel affordable without compromising on comfort and quality."
        }
      ],
      tableData: [
        ['-Mini bus on rent in Hadapsar Pune', '-Mini AC Bus On Rent in Wanowrie Pune'],
        ['-Top Mini Bus On Rent in Kothrud Pune', '- Mini bus on rent in Kharadi Pune'],
        ['-Mini bus on rent in Kalyani Nagar Pune', '-Mini bus on rent in Shivaji Nagar'],
        ['-Best Mini Bus On Rent in Baner Pune', '-Mini bus on rent for corporate events in Pune'],
        ['-Minibus hire for wedding party in Pimpri Chinchwad', '-Minibus on rent in Pune Outstations'],
        ['-Pune to Shirdi Mini Bus on Rent', '-Mini bus on rent in Pune for family'],
        ['-Mini bus on rent in Pune for family with driver', ''],
      ],
      whychoose:[
        {
          WhyChooseheading:'Why Choose Amruta Travels for Mini Bus on Rent in Pune?',
          WhyChoosedescription:"When it comes to renting a mini bus in Pune, Amruta Travels stands out as a trusted and reliable service provider. Whether you're organizing a corporate event, a family trip, or a group outing, our fleet of modern and well-maintained mini buses guarantees a comfortable, safe, and enjoyable travel experience. Here's why Amruta Travels should be your top choice for mini bus rentals in Pune:",

        },
        {
          WhyChooseheading:'Comfortable and Spacious Vehicles:',
          WhyChoosedescription:"We pride ourselves on offering mini buses that are not only modern but also incredibly spacious. Our vehicles are equipped with well-cushioned seats, ensuring that passengers have plenty of legroom and space to move. Whether you’re traveling within Pune or to an outstation destination, comfort is key to a great experience, and we ensure that you enjoy every moment of your journey.",

        },
        {
          WhyChooseheading:'Modern Amenities:',
          WhyChoosedescription:"At Amruta Travels, we believe that traveling should be more than just reaching your destination—it should be an enjoyable experience. Our mini buses are packed with modern amenities that enhance your journey. With powerful air-conditioning systems, you can remain cool and comfortable even in the hottest weather. Additionally, we offer high-quality sound systems, entertainment options such as LED screens, and Bluetooth connectivity to make your trip fun and engaging, especially for long-distance travels.",

        },
        {
          WhyChooseheading:'Experienced and Professional Drivers:',
          WhyChoosedescription:"Our drivers are not only skilled but are also familiar with Pune’s traffic patterns, roadways, and the best routes to take. They are highly professional, ensuring a smooth and stress-free ride for you and your group. Whether navigating through busy city streets or driving along scenic outstation routes, our drivers ensure a safe and punctual arrival at your destination.",

        },
        {
          WhyChooseheading:'Affordable Pricing:',
          WhyChoosedescription:"We understand the importance of staying within budget, which is why we offer competitive and transparent pricing with no hidden fees. Whether you need a mini bus for a few hours or an entire day, we provide detailed quotes upfront, ensuring there are no surprises when it comes to payment. Our rates are designed to provide you with the best value for your money, offering premium services at affordable prices.",

        },
        {
          WhyChooseheading:'Customized Solutions:',
          WhyChoosedescription:"We understand that no two trips are the same. That's why we offer fully customizable packages to suit your specific travel needs. Whether you're looking for a mini bus for a corporate event, a wedding party, a school trip, or a religious pilgrimage, we can tailor our services to fit your itinerary and preferences.",

        },
        {
          WhyChooseheading:'Excellent Customer Service:',
          WhyChoosedescription:"At Amruta Travels, customer satisfaction is our top priority. From the moment you inquire about our services until the end of your journey, our dedicated customer service team is here to assist you. Whether you have questions about our pricing, need to make changes to your booking, or have specific travel requests, we are always available to provide prompt and helpful support.",

        },
        {
          WhyChooseheading:'Punctual and Reliable:',
          WhyChoosedescription:"When you choose Amruta Travels, you can count on us to be punctual. We understand how important time is for corporate events, family gatherings, or day trips, which is why we always adhere to the schedule you provide. Our drivers ensure that you arrive at your destination on time, allowing you to enjoy your event or meeting without any stress.",

        },
        {
          WhyChooseheading:'Well-Maintained Fleet:',
          WhyChoosedescription:"Our fleet of mini buses is regularly serviced and maintained to the highest standards. Cleanliness and hygiene are key priorities for us, ensuring that our vehicles are always in pristine condition when they arrive to pick you up. Regular inspections are conducted to ensure all vehicles meet safety and performance standards, allowing you to travel with peace of mind.",

        },
        {
          WhyChooseheading:'Easy Booking Process:',
          WhyChoosedescription:"We’ve made the booking process simple and convenient. Whether you prefer to book via phone, email, or our user-friendly online booking platform, you can reserve your mini bus in just a few steps. Our system allows you to specify all your requirements upfront, and our team is always available to assist with any special requests or modifications to your booking.",

        },
        {
          WhyChooseheading:'Safety First:',
          WhyChoosedescription:"At Amruta Travels, the safety of our passengers is our highest priority. All our mini buses are equipped with top-of-the-line safety features, including seat belts for all passengers, emergency exits, fire extinguishers, and fully stocked first-aid kits. Our drivers are also trained to handle emergency situations, ensuring that your journey is as safe as possible.",

        }

      ]

    
 
  
    



    };

    
    const testimonials = [
        {
          name: "Miss. Priya Sharma",
          text: "I recently rented a Tempo Traveller from Amruta Travels for a family reunion, and I couldn’t be more pleased with the service. The booking process was straightforward, and the vehicle arrived on time, clean, and well-maintained. The spacious interior made our trip incredibly comfortable, and the driver was professional and friendly. It was a pleasure to work with Amruta Travels, and I highly recommend them for anyone looking for reliable and comfortable group transportation in Pune."
        },
        {
          name: "Mr. Rajesh Patel",
          text: "Our corporate team needed a Tempo Traveller for an off-site meeting, and Amruta Travels exceeded our expectations. The vehicle was luxurious, equipped with all the modern amenities we needed, and the driver was knowledgeable and accommodating. The entire experience was seamless, from booking to drop-off. We appreciated the flexibility and excellent customer service provided by Amruta Travels. We’ll definitely use their services again for our future group travel needs."
        }
      ];
      
        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      const faqData = [
        {
            question: "How can I book a bus from Pune to Panvel with Amruta Travels?",
            answer: "You can book a bus by calling us at +91 9673101100 or through our website. Our team will help you with the booking process and provide all necessary details."
          },
          {
            question: "What types of bus are available for the Pune to Panvel journey?",
            answer: "We offer a variety of bus, including standard and luxury options, to accommodate different preferences and budgets."
          },
          {
            question: "Are there any additional charges for the bus service?",
            answer: "Our pricing is transparent, with no hidden fees. Any additional charges, such as for extra stops or extended mileage, will be clearly communicated beforehand."
          },
          {
            question: "How do I know if the bus is well-maintained?",
            answer: "All our vehicles undergo regular inspections and maintenance to ensure they are in excellent condition, prioritizing your safety and comfort."
          },
          {
            question: "Can I choose the driver for my trip?",
            answer: "While we assign experienced drivers to ensure a high-quality experience, you can let us know of any specific preferences or requirements when booking."
          },
          {
            question: "What if I need to change my booking?",
            answer: "Contact our customer support team as soon as possible to discuss any changes to your booking. We will do our best to accommodate your requests based on availability."
          },
          {
            question: "Is there a minimum rental period for the bus service?",
            answer: "We offer flexible rental periods to fit your needs, whether you require the bus for a one-way trip or a round trip."
          },
          {
            question: "What amenities are included in the bus service?",
            answer: "Our bus come with modern amenities such as air conditioning, comfortable seating, and more to ensure a pleasant journey. Specific features may vary depending on the vehicle type."
          },
          {
            question: "What is the cancellation policy for the bus service?",
            answer: "Cancellation policies may vary based on the booking details. Please contact us for information on our cancellation terms and any applibusle fees."
          },
          {
            question: "How can I contact Amruta Travels for support during my journey?",
            answer: "You can reach our 24/7 customer support team at +91 9673101100 for any assistance or issues during your trip. We are here to ensure your journey is smooth and enjoyable."
          }
        
      ];
      
      const contactData = {
        heading: "Tempo Traveller On Rent in Pune contact Number:",
        contactNumbers: [
          "+91 8421333435",
          "+91 7875774499",
          "+91 7620411277",
          "+91 9284718314"
        ],
        email: "booking@amrutatravels.com"
      };


      const Images = [
        {
            place: "/img/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/img/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/img/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/img/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/img/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/img/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/img/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/img/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];
     
    return(
        <div>
    <Helmet>
        <title>Contact: +91 8421333435 | Amruta Travel | Pune to Mahabaleshwar Tempo Traveller on Rent | Affordable 17 Seater for Your Trip</title>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Amruta Travels",
              "description": "Affordable Tempo Traveller Rentals from Pune to Mahabaleshwar, including Panchgani and Tapola. Spacious 17 Seater options, perfect for family and group trips.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Shivaji Nagar, Pune",
                "addressLocality": "Pune",
                "addressRegion": "Maharashtra",
                "postalCode": "411005",
                "addressCountry": "IN"
              },
              "telephone": "+91-8421333435",
              "url": "https://amrutatravel.com/pune-to-mahabaleshwar-tempo-traveller",
              "logo": "https://amrutatravel.com/images/pune-to-mahabaleshwar-tempo-traveller.jpg",
              "image": "https://amrutatravel.com/images/pune-to-mahabaleshwar-tempo-traveller.jpg",
              "priceRange": "₹5000 - ₹15000",
              "offers": {
                "@type": "Offer",
                "url": "https://amrutatravel.com/pune-to-mahabaleshwar-tempo-traveller",
                "priceCurrency": "INR",
                "price": "5000",
                "priceValidUntil": "2024-12-31"
              }
            }
          `}
        </script>
      </Helmet>

<section className="page-header">
      <div className="page-header-shape"></div>
      <div className="container">
        <div className="page-header-info">
          <h4>{cardData.keyword}</h4>
          <h2>
            Feel your journey <br /> with <span>Amruta Travels</span>
          </h2>
          
        </div>
      </div>
    </section>

<section>
    <div className="container-fluid ">
<div className="row">
<div className="col-12 col-md-7">
    <img src='\img\Keyword Based Image\Keyword Based Image-02.jpg' alt='img'/>
<h3 className="py-1">{cardData.heading} </h3><p>{cardData.headingDescription}</p>
<div>
      {cardData.topPlaces.map((place, index) => (
        <div key={index}>
          <h3 className="py-1 Colo">{place.title}</h3>
          <p>{place.description}</p>
        </div>
      ))}
    </div>
    <div>
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 Colo">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>

   <div id="why-choose-section">
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 Colo">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
<div>
      <h3 className='Colo'>FAQs About Pune to Panvel bus for Amruta Travels</h3>
      <Accordion data={faqData} />
    </div>

    <CustomCarousel items={carouselItems} options={carouselOptions} />

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='Colo'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='Colo'>Email Id:</strong></p>
        <p>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" >
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row ">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded p-3 text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8421333435" className="d-block  whit">+91 8421333435</a>
            <a href="tel:+91 7875774499" className="d-block  whit">+91 7875774499</a>
            <a href="tel:+91 7620411277" className="d-block  whit">+91 7620411277</a>
            <a href="tel:+91 9284718314" className="d-block  whit">+91 9284718314</a>
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded p-3 text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@amrutatravel.com" className="whit d-block">
              booking@amrutatravel.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border  rounded p-3 text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
              Amruta Travels:<br />
              Office No.- 2, Ground Floor,<br />
              Wings H-(Puram), Prayeja City,<br />
              Phase-2, Near Prayeja City,<br />
              Vadgaon Budruk,<br />
              Pune, Maharashtra - 411041
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<FareCard/>

</div>
    </div>
</section>



        </div>
    );
}
export default Mahabaleshwar;