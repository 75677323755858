import React from 'react';
import { Helmet } from 'react-helmet';
const Blog = () => {
    const cardData = 
    {
        "keyword": "Blog",
    }

    const Images = [
        {
            place: "/img/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/img/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/img/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/img/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/img/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/img/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/img/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/img/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];

           
const schemaData = 
{
   "@context": "https://schema.org",
  "@type": "TravelAgency",
  "name": "Amruta Travels",
  "description": "Explore top-notch travel services with Amruta Travels. Book luxury mini buses, personalized tours, and travel packages for corporate events, family trips, and more in Pune and surrounding areas.",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "",
    "addressLocality": "Pune",
    "addressRegion": "MH",
    "postalCode": "411041",
    "addressCountry": "IN"
  },                                    "aggregateRating": {
    "@type": "AggregateRating",
    "bestRating": "5",
    "worstRating": "1",
    "ratingValue": "4.8",
    "ratingCount": "9000"
  },
  "telephone": "+91 8421333435",
  "image": "https://amrutatravel.com/img/OG-Image/OG-Image-01.jpg",
  "sameAs": [
    "https://www.facebook.com/amrutatravels",
    "https://www.instagram.com/amrutatravels",
    "https://www.linkedin.com/company/amrutatravels"
  ]
}



  return (
    
    <div>


<Helmet>
  <title>Contact: +91 8421333435 | Amruta Travels | Tempo Traveller On Rent in Pune | Luxury Tempo Traveller Hire for Outstation & Local Trips</title>
  <meta name="description" content="Hire a Tempo Traveller in Pune for local or outstation trips. Affordable rates for 17 seater and luxury vehicles. Book your ride to Shirdi, Mahabaleshwar, Goa & more with Amruta Travels." />
  <meta name="keywords" content="Tempo traveller in Pune, 17 seater bus on rent in Pune price, 17 seater tempo traveller on rent in Pune, Traveller on rent Pune, Traveller bus on rent in Pune, Tempo Traveller on rent Pune to Outstation, Tempo traveller on rent in Pune, 17 seater tempo traveller on rent in Pune, Pune to Shirdi tempo traveller rate, Tempo Traveller hire in Pune, Tempo Traveller on rent in Hadapsar, Tempo Traveller on rent in Pune rate per km, Tempo Traveller Rental Pune, Pune to Mahabaleshwar cab tempo traveller, Pune to Goa tempo traveller on Rent, Luxury Tempo Traveller on rent in Pune" />
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "Tempo Traveller On Rent in Pune",
        "description": "Affordable Tempo Traveller rentals for local and outstation trips in Pune. Choose from 17-seater to luxury Tempo Travellers for trips to Shirdi, Mahabaleshwar, Goa, and more. Book with Amruta Travels for a comfortable journey.",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Office No.- 2, Ground Floor, Wings H-(Puram), Prayeja City, Phase-2, Near Prayeja City, Vadgaon Budruk",
          "addressLocality": "Pune",
          "addressRegion": "Maharashtra",
          "postalCode": "411041",
          "addressCountry": "IN"
        },
        "telephone": "+91-8421333435",
        "url": "https://amrutatravel.com/tempo-traveller-on-rent-in-pune",
        "logo": "https://amrutatravel.com/images/tempo-traveller-on-rent.jpg",
        "image": "https://amrutatravel.com/images/tempo-traveller-on-rent.jpg",
        "priceRange": "₹5000 - ₹25000",
        "offers": {
          "@type": "Offer",
          "url": "https://amrutatravel.com/tempo-traveller-on-rent-in-pune",
          "priceCurrency": "INR",
          "price": "7000",
          "priceValidUntil": "2024-12-31"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "9000"
        },
        "review": [
          {
            "@type": "Review",
            "author": {
              "@type": "Person",
              "name": "Ravi Kulkarni"
            },
            "datePublished": "2024-05-10",
            "reviewBody": "Booked a 17-seater Tempo Traveller for a family trip to Mahabaleshwar. The vehicle was comfortable and well-maintained. Great service from Amruta Travels!"
          },
          {
            "@type": "Review",
            "author": {
              "@type": "Person",
              "name": "Priya Joshi"
            },
            "datePublished": "2024-07-15",
            "reviewBody": "The luxury Tempo Traveller was perfect for our trip to Goa. Spacious and comfortable ride, with excellent driver service. Highly recommend!"
          }
        ],
        "serviceArea": {
          "@type": "Place",
          "name": "Pune",
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": "18.5204",
            "longitude": "73.8567"
          }
        },
        "availableChannel": {
          "@type": "ServiceChannel",
          "serviceUrl": "https://amrutatravel.com/tempo-traveller-on-rent-in-pune"
        },
        "keywords": "Tempo traveller in Pune, 17 seater bus on rent in Pune price, 17 seater tempo traveller on rent in Pune, Traveller on rent Pune, Traveller bus on rent in Pune, Tempo Traveller on rent Pune to Outstation, Tempo traveller on rent in Pune, 17 seater tempo traveller on rent in Pune, Pune to Shirdi tempo traveller rate, Tempo Traveller hire in Pune, Tempo Traveller on rent in Hadapsar, Tempo Traveller on rent in Pune rate per km, Tempo Traveller Rental Pune, Pune to Mahabaleshwar cab tempo traveller, Pune to Goa tempo traveller on Rent, Luxury Tempo Traveller on rent in Pune"
      }
    `}
  </script>
</Helmet>

        <section className="page-header">
      <div className="page-header-shape"></div>
      <div className="container">
        <div className="page-header-info">
          <h4>{cardData.keyword}</h4>
          <h2>
            Feel your journey <br /> with <span>Amruta Travels</span>
          </h2>
          
        </div>
      </div>
    </section>

        <section>
            <div className="container px-0">
                <div className="row g-4 justify-content-center">
                    {/* News Card Section */}
                    <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s">
                        <div className="news-card-items mt-0">
                            <div className="news-image">
                                <img src="/img/blog-img.jpg" alt="news-img" />
                            </div>
                            <div className="news-content">
                                <div className="news-cont">
                                    <h3>
                                        <p href="" style={{color:'#ff7700'}}>
                                            Discover India's Hidden Treasures: Unforgettable Journeys with Amruta Travel.
                                        </p>
                                    </h3>
                                    <p>At Amruta Travel Travels, we believe that the soul of travel lies in uncovering the hidden treasures that make India so extraordinary.</p>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                    


                    <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}
              </div>
                  


                </div>
            </div>
        </section>
        </div>
    );
}

export default Blog;
