import { Table } from 'react-bootstrap';
import Accordion from './Accordion';
import CustomCarousel from './CustomCarousel'; 
import { Helmet } from 'react-helmet';
import './smallkey.css';
import FareCard from './FareCard';
// import  Table  from './pages/Table';
// import Table from './pages/Table.js';
function  BusOnRentInPune(){

  const cardData = 
    {
      keyword: 'Bus on Rent in Pune ',
      heading: 'Amruta Travels: Bus on Rent in Pune ',

      headingDescription: 'If you’re looking for reliable and affordable bus rental services in Pune, Amruta Travels has you covered. We offer a diverse fleet of buses tailored to various needs, from corporate events to school trips and private tours. Here is a guide to our top bus rental options in Pune that ensure comfort, safety, and convenience for every journey:',

      top:"Top Bus on Rent in Pune:",
       topPlaces : [
        {
            "title": "Luxury Bus on Rent in Pune",
            "description": "Experience the epitome of comfort with our luxury bus rentals. Ideal for corporate events, weddings, or high-profile gatherings, our luxury buses are equipped with plush seating, modern amenities, and top-notch service. Enjoy a premium travel experience with Amruta Travels’ luxury bus rentals."
        },
        {
            "title": "40-Seater Bus on Rent in Pune",
            "description": "Perfect for large groups, our 40-seater buses provide ample space and comfort. Whether it’s for a corporate outing, family reunion, or a group tour, these buses offer a comfortable ride with features designed to keep everyone relaxed throughout the journey."
        },
        {
            "title": "32-Seater Bus on Rent in Pune",
            "description": "Our 32-seater buses are a great choice for medium-sized groups. They offer a balance between space and affordability, making them ideal for school trips, picnics, and other group activities. With comfortable seating and reliable service, your journey will be smooth and enjoyable."
        },
        {
            "title": "17-Seater Mini Bus on Rent in Pune",
            "description": "For smaller groups or short trips, our 17-seater mini buses provide a cozy and efficient travel solution. Perfect for family outings or small corporate events, these mini buses are well-maintained and designed for a pleasant travel experience."
        },
        {
            "title": "Tempo Traveller on Rent in Pune",
            "description": "Our tempo travellers are ideal for flexible travel needs, offering space for both passengers and luggage. Whether you’re planning a day trip or an extended journey, our tempo travellers provide a comfortable and practical option for diverse travel requirements."
        },
        {
            "title": "Mini Bus on Rent in Pune",
            "description": "For those who need a compact yet comfortable travel option, our mini buses are the perfect choice. They are ideal for smaller groups, offering convenience and ease of movement without compromising on comfort."
        },
        {
            "title": "Luxury Mini Bus on Rent in Pune",
            "description": "Our luxury mini buses combine the benefits of compact size with luxury features. They are perfect for small groups looking for a premium travel experience. Enjoy plush interiors and modern amenities while traveling in style."
        },
        {
            "title": "Bus on Rent in Hadapsar",
            "description": "Amruta Travels offers specialized bus rental services in Hadapsar, ensuring that local and outstation trips are covered with ease. Our buses are well-maintained and ready to provide a smooth and comfortable travel experience."
        },
        {
            "title": "Bus on Rent in Baner",
            "description": "Baner residents and businesses can benefit from our reliable bus rental services. Whether it’s for corporate events, school outings, or personal trips, our Baner-based services ensure timely and efficient transportation solutions."
        },
        {
            "title": "Bus on Rent in Pimpri Chinchwad",
            "description": "For those in Pimpri Chinchwad, Amruta Travels offers a range of bus rental options tailored to local and outstation needs. Our fleet includes various bus sizes and types, providing flexibility and comfort for any journey."
        }
    ],    
    "services": [
      {
          "name": "Pune to Goa Mini Bus on Rent:",
          "description": "Experience the scenic journey from Pune to Goa with our mini bus rentals. Ideal for small to medium-sized groups, our mini buses provide comfort and convenience, ensuring a smooth and enjoyable trip."
      },
      {
          "name": "Pune to Goa Tempo Traveller on Rent:",
          "description": "For a more flexible travel experience, opt for our tempo travellers. Perfect for group trips to Goa, these vehicles offer ample space and comfort, making your journey enjoyable and hassle-free."
      },
      {
          "name": "Amruta Travels Pune:",
          "description": "Amruta Travels is your trusted partner for reliable bus rental services from Pune to Goa. Our extensive fleet and exceptional service ensure a comfortable and enjoyable travel experience."
      },
      {
          "name": "Pune to Goa Urbania on Rent:",
          "description": "Travel in style with our Urbania buses. Equipped with modern amenities, these buses provide a luxury travel experience for your journey from Pune to Goa, ensuring comfort and convenience."
      },
      {
          "name": "Pune to Goa 17 Seater on Rent:",
          "description": "Our 17-seater buses are perfect for small groups traveling from Pune to Goa. Enjoy a cozy and comfortable ride with ample space for everyone and their belongings."
      },
      {
          "name": "Pune to Goa 14, 17, 25, 32 Seater on Rent:",
          "description": "Choose from a range of bus sizes to suit your group’s needs. Whether you need a 14-seater, 17-seater, 25-seater, or 32-seater, we have the perfect bus for your trip to Goa."
      },
      {
          "name": "Pune to Goa 35, 45, 50 Seater on Rent:",
          "description": "For larger groups, our 35-seater, 45-seater, and 50-seater buses offer ample space and comfort. Enjoy a spacious and relaxing journey from Pune to Goa with our well-maintained buses."
      },
      {
          "name": "Pune to Goa Bus Package on Rent:",
          "description": "Opt for our comprehensive bus packages for your trip from Pune to Goa. These packages offer great value and convenience, covering everything from transportation to additional services."
      },
      {
          "name": "Hire Pune to Goa Mini Bus & Tempo Traveller on Rent:",
          "description": "Combine the flexibility of tempo travellers with the comfort of mini buses. Our rental options cater to various group sizes and preferences for your journey from Pune to Goa."
      },
      {
          "name": "Hire Tempo Traveller Minibus:",
          "description": "For a versatile travel solution, consider hiring a tempo traveller or mini bus. These options provide comfort and space for your group while traveling from Pune to Goa."
      },
      {
          "name": "Bus on Rent in Pune:",
          "description": "Amruta Travels offers a wide range of buses for rent in Pune. Whether you need a bus for a local trip or an outstation journey, we have the right vehicle to meet your needs."
      },
      {
          "name": "Pune to Goa Bus Hire on Rent:",
          "description": "Our bus hire services for the Pune to Goa route ensure a smooth and comfortable journey. Choose from our fleet of well-maintained buses to suit your travel requirements."
      },
      {
          "name": "Pune to Goa AC Bus on Rent:",
          "description": "Travel in comfort with our AC buses for your journey from Pune to Goa. Our air-conditioned buses provide a cool and pleasant environment, making your trip more enjoyable."
      },
      {
          "name": "Pune to Goa Bus Rental Service:",
          "description": "Amruta Travels provides top-notch bus rental services for trips from Pune to Goa. Our professional drivers and well-maintained buses ensure a seamless and pleasant travel experience."
      },
      {
          "name": "Hire a Bus from Pune to Goa:",
          "description": "Whether you need a mini bus, tempo traveller, or a large coach, Amruta Travels offers flexible bus hire options for your journey from Pune to Goa. Enjoy a comfortable and convenient trip with us."
      },
      {
          "name": "Bus Rental Service Pune to Goa:",
          "description": "For reliable and efficient bus rental services from Pune to Goa, choose Amruta Travels. Our extensive fleet and exceptional service guarantee a pleasant journey for your group."
      },
      {
          "name": "Pune to Goa Holiday Bus Rental:",
          "description": "Make your holiday to Goa memorable with Amruta Travels’ bus rental services from Pune. Our well-maintained buses are perfect for long-distance travel, offering comfort and reliability for a seamless journey. Whether you're planning a leisurely holiday or an adventurous trip, our buses ensure a pleasant travel experience."
      },
      {
          "name": "Pune to Goa Family Trip Bus Hire:",
          "description": "Traveling with family to Goa? Choose Amruta Travels for your bus hire needs. Our family-friendly buses provide ample space and comfort for a relaxed and enjoyable trip. With modern amenities and a focus on safety, our bus rental service is ideal for family vacations, making your journey from Pune to Goa smooth and pleasant."
      },
      {
          "name": "Pune to Goa Weekend Getaway Bus Rental:",
          "description": "For a quick weekend getaway to Goa, Amruta Travels offers convenient bus rental services. Our buses are designed for comfort and efficiency, ensuring a stress-free journey from Pune to Goa. Enjoy a relaxing weekend trip with our reliable and well-equipped buses that make traveling enjoyable and hassle-free."
      },
      {
          "name": "Pune to Goa Corporate Bus Hire:",
          "description": "Amruta Travels provides specialized corporate bus hire services for trips from Pune to Goa. Whether it's for a company retreat, team-building event, or corporate meeting, our buses offer a professional and comfortable travel experience. With features tailored to corporate needs, our service ensures that your business trip to Goa is both productive and pleasant."
      },
      {
          "name": "Pune to Goa Group Travel Bus Rental:",
          "description": "For group travel from Pune to Goa, Amruta Travels offers a range of bus rental options to accommodate different group sizes. Our buses are ideal for tours, group outings, and other collective travel needs. Experience a comfortable and enjoyable journey with our reliable bus rental services, tailored to make group travel easy and efficient."
      },
      {
          "name": "AC Bus Hire Pune to Goa:",
          "description": "Travel in comfort with Amruta Travels’ AC bus hire service for trips from Pune to Goa. Our air-conditioned buses are designed to provide a cool and comfortable environment, making long-distance travel more enjoyable. Perfect for those seeking a premium travel experience, our AC buses ensure a pleasant journey to Goa."
      },
      {
          "name": "Comfortable Bus from Pune to Goa:",
          "description": "Amruta Travels prioritizes comfort with our bus rental services for journeys from Pune to Goa. Our buses are equipped with modern amenities and spacious seating to ensure a comfortable ride. Whether you're traveling for leisure or business, our comfortable buses provide a relaxing and enjoyable travel experience."
      },
      {
          "name": "32-Seater Bus Rental Pune to Goa:",
          "description": "For medium-sized groups traveling from Pune to Goa, consider our 32-seater bus rental option. Amruta Travels offers well-maintained buses that provide ample space and comfort for your journey. Ideal for family groups, friends, or small corporate teams, our 32-seater buses ensure a smooth and enjoyable trip to Goa."
      },
      {
          "name": "50-Seater Bus Hire from Pune to Goa:",
          "description": "If you're planning a large group trip to Goa, Amruta Travels offers 50-seater bus rentals to accommodate your needs. Our spacious buses are perfect for larger groups, providing plenty of room and comfort for a long journey. Enjoy a group travel experience with our reliable and comfortable 50-seater buses."
      },
      {
          "name": "Tempo Traveller on Rent in Pune for Corporate Events:",
          "description": "For corporate events, Amruta Travels offers tempo travellers on rent in Pune. Our tempo travellers are ideal for transporting corporate teams and executives, providing a comfortable and efficient travel option. With modern amenities and a focus on professionalism, our tempo travellers ensure a successful corporate event experience."
      },
      {
          "name": "Luxury Tempo Traveller on Rent in Pashan Pune:",
          "description": "Experience premium travel with our luxury tempo traveller rentals in Pashan, Pune. Amruta Travels offers luxury tempo travellers equipped with high-end features for a sophisticated travel experience. Perfect for special occasions, corporate events, or upscale family trips, our luxury tempo travellers provide exceptional comfort and style."
      },
      {
          "name": "Bus On Rent in Pune Contact Number:",
          "description": "Contact Amruta Travels at +91 8421333435 for prompt and efficient Bus On Rent in Pune. We ensure a smooth and enjoyable ride for all our customers."
      }
  ],

  "tableData": [
    ["-Pune to Goa Mini Bus on Rent", "-Pune to Goa Tempo Traveller on Rent"],
    ["-Amruta Travels Pune", "-Pune to Goa Urbania on Rent"],
    ["-Pune to Goa 17 Seater on Rent", "-Pune to Goa 14, 17, 25, 32 Seater on Rent"],
    ["-Pune to Goa 35, 45, 50 Seater on Rent", "-Pune to Goa Bus Package on Rent"],
    ["-Hire Pune to Goa Mini Bus & Tempo Traveller on Rent", "-Hire Tempo Traveller Minibus"],
    ["-Bus on Rent in Pune", "-Pune to Goa Bus Hire on Rent"],
    ["-Pune to Goa AC Bus on Rent", "-Pune to Goa Bus Rental Service"],
    ["-Hire a Bus from Pune to Goa", "-Pune to Goa Holiday Bus Rental"],
    ["-Pune to Goa Family Trip Bus Hire", "-Pune to Goa Weekend Getaway Bus Rental"],
    ["-Pune to Goa Corporate Bus Hire", "-Pune to Goa Group Travel Bus Rental"],
    ["-AC Bus Hire Pune to Goa", "-Comfortable Bus from Pune to Goa"],
    ["-32-Seater Bus Rental Pune to Goa", "-50-Seater Bus Hire from Pune to Goa"],
    ["-Tempo Traveller on Rent in Pune for Corporate Events", "-Luxury Tempo Traveller on Rent in Pashan Pune"]
],


"whychoose": [
  {
      "WhyChooseheading": "Why Choose Amruta Travels for Bus on Rent in Pune?",
      "WhyChoosedescription": "Amruta Travels offers exceptional bus rental services in Pune, catering to various needs with a focus on comfort, safety, and affordability."
  },
  {
      "WhyChooseheading": "Extensive Fleet Options:",
      "WhyChoosedescription": "Our fleet includes a variety of buses to suit different group sizes and needs, from luxury coaches to standard AC/non-AC buses. Whether you're planning a small family trip or need transport for a large event, we have the right vehicle for you."
  },
  {
      "WhyChooseheading": "Comfort & Safety:",
      "WhyChoosedescription": "Each bus is well-maintained, equipped with modern amenities like comfortable seating, air conditioning, and entertainment systems to ensure a pleasant journey. Safety is our priority, and all our buses are regularly serviced and meet the highest safety standards."
  },
  {
      "WhyChooseheading": "Experienced & Professional Drivers:",
      "WhyChoosedescription": "Our drivers are not only skilled and experienced but also courteous and familiar with local routes, ensuring a smooth and timely travel experience. They are trained to prioritize passenger safety and customer service."
  },
  {
      "WhyChooseheading": "Affordable & Transparent Pricing:",
      "WhyChoosedescription": "We believe in providing value for money with transparent pricing structures and no hidden costs. Whether you need a bus for a short city tour or an outstation trip, our competitive rates are designed to fit your budget."
  },
  {
      "WhyChooseheading": "Customizable Packages:",
      "WhyChoosedescription": "We offer flexible and customizable packages tailored to your specific travel requirements. Whether it’s a corporate outing, wedding transportation, school trips, or sightseeing tours, we can personalize the service to match your schedule and preferences."
  },
  {
      "WhyChooseheading": "24/7 Customer Support:",
      "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist you with bookings, route planning, and any other queries you may have, ensuring a hassle-free experience from start to finish."
  },
  {
      "WhyChooseheading": "Punctuality and Reliability:",
      "WhyChoosedescription": "At Amruta Travels, we understand the importance of time. We ensure punctual arrivals and departures, making sure your event or trip runs smoothly without delays."
  }
]
};

const faqData = [
  {
      "question": "What types of buses are available for rent?",
      "answer": "We offer a variety of buses including luxury coaches, semi-luxury buses, AC/non-AC buses, and mini-buses to accommodate different group sizes and preferences."
  },
  {
      "question": "How many passengers can your buses accommodate?",
      "answer": "Our buses can accommodate anywhere between 18 to 50 passengers, depending on the model you choose."
  },
  {
      "question": "Can I book a bus for an outstation trip?",
      "answer": "Yes, we provide bus rental services for both local travel within Pune and outstation trips across Maharashtra and beyond."
  },
  {
      "question": "How do I make a booking for a bus on rent?",
      "answer": "You can book a bus through our website, call our customer service hotline, or visit our office. We recommend booking in advance, especially during peak seasons."
  },
  {
      "question": "Do your buses come with a driver?",
      "answer": "Yes, all our buses come with professional, experienced drivers who are familiar with local and long-distance routes to ensure a safe and smooth journey."
  },
  {
      "question": "What are your payment options?",
      "answer": "We accept multiple payment methods, including online transfers, credit/debit cards, and cash payments."
  },
  {
      "question": "Are there any additional charges for fuel or tolls?",
      "answer": "Our pricing is transparent, and any additional charges such as fuel and tolls will be clearly mentioned upfront during the booking process."
  },
  {
      "question": "Can I rent a bus for a few hours?",
      "answer": "Yes, we offer flexible rental options, whether you need a bus for a few hours, a full day, or an extended trip."
  },
  {
      "question": "Is there a cancellation policy?",
      "answer": "Yes, we have a cancellation policy in place. You can cancel your booking; however, cancellation charges may apply depending on the timing of the cancellation."
  }
];




const testimonials = [
  {
      "name": "Mr. Rahul Mehta",
      "text": "We booked a luxury bus from Amruta Travels for our corporate event, and the experience was fantastic! The bus was extremely comfortable, spacious, and well-maintained. The driver was very professional and navigated through the city smoothly. The entire team at Amruta Travels ensured that our group was well taken care of from start to finish. I highly recommend their bus rental service in Pune for any occasion!"
  },
  {
      "name": "Miss Sneha Kulkarni",
      "text": "Amruta Travels made our family trip to Lonavala truly enjoyable! We rented a bus for 30 people, and the service exceeded our expectations. The booking process was easy, the rates were very reasonable, and the bus arrived right on time. Our driver was friendly, skilled, and knew the best routes. We felt safe and comfortable throughout the journey. I will definitely use their services again for future trips!"
  }
];

        const carouselItems = testimonials.map(testimonial => (
          <div key={testimonial.name}>
            <blockquote>
              <p>{testimonial.text}</p>
              <footer>— {testimonial.name}</footer>
            </blockquote>
          </div>
        ));
      
        const carouselOptions = {
          loop: true,
          margin: 10,
          nav: true,
          items: 1
        };
      
      const contactData = {
        heading: "Bus On Rent in Pune Contact Number:",
        contactNumbers: [
          "+91 8421333435",
          "+91 7875774499",
          "+91 7620411277",
          "+91 9284718314"
        ],
        email: "booking@amrutatravels.com"
      };


      const Images = [
        {
            place: "/img/tempo4.jpg",
            text: "Tempo Traveller Hire in Pune",
          },
          {
            place: "/img/mini5.jpg",
            text: "Mini Bus On Rent in Pune",
          },
        {
          place: "/img/bus32-2.jpg",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/img/bus45-3.jpg",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
            place: "/img/luxury6.jpg",
            text: "Luxury Bus Rental in Pune",
          },
          {
            place: "/img/force-urbania.jpg",
            text: "Force Urbania On Rent in Pune",
          },
          {
            place: "/img/mini5.jpg",
            text: "Bus Service for Picnic in Pune",
          },
          {
            place: "/img/tempo4.jpg",
            text: "17 Seater Tempo Traveller on Rent in Pune",
          },
          ];
     
    return(
        <div>
<Helmet>
  <title>Contact: +91 8421333435 | Amruta Travels | Bus Hire for Goa Trip from Pune | Mini Bus, Tempo Traveller & Luxury Bus Hire</title>
  <meta name="description" content="Book buses and tempo travellers for your Goa trip from Pune. Choose from a range of bus options including mini buses, 17-seater, 32-seater, and luxury buses for comfortable travel." />
  <meta name="keywords" content="Pune to Goa Mini Bus on Rent, Pune to Goa Tempo Traveller on Rent, Pune to Goa Urbania on Rent, Pune to Goa 17 Seater on Rent, Pune to Goa 14 17 25 32 Seater on Rent, Pune to Goa 35 45 50 Seater on Rent, Pune to Goa Bus package on Rent, Hire Pune to Goa Mini Bus & Tempo Traveller on Rent, Hire Tempo Traveller Minibus, Bus on Rent in Pune, Pune to Goa bus Hire on Rent, Pune to Goa Ac Bus On Rent, Pune to Goa bus Rental Service, Hire a bus from Pune to Goa, Bus Rental Service Pune to Goa, Pune to Goa Holiday Bus Rental, Pune to Goa family Trip Bus Hire, Pune to Goa Weekend Getaway Bus Rental, Pune to Goa Corporate bus Hire, Pune to Goa Group Travel Bus Rental, AC bus Hire Pune to Goa, Comfortable Bus from Pune to Goa, 32 seater bus rental Pune to Goa, 50 seater bus hire from Pune to Goa, tempo traveller on rent in pune for corporate events, luxury tempo traveller on rent in pashan pune" />
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Amruta Travels",
        "description": "Amruta Travels offers premium bus hire services for Goa trips from Pune. Rent mini buses, tempo travellers, and luxury buses for family, group, or corporate travel.",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Office No.- 2, Ground Floor, Wings H-(Puram), Prayeja City, Phase-2, Near Prayeja City, Vadgaon Budruk",
          "addressLocality": "Pune",
          "addressRegion": "Maharashtra",
          "postalCode": "411041",
          "addressCountry": "IN"
        },
        "telephone": "+91-8421333435",
        "url": "https://amrutatravel.com/bus-on-rent-in-pune-for-goa-trip",
        "logo": "https://amrutatravel.com/images/bus-hire-goa-trip.jpg",
        "image": "https://amrutatravel.com/images/bus-hire-goa-trip.jpg",
        "priceRange": "₹4500 - ₹35000",
        "offers": {
          "@type": "Offer",
          "url": "https://amrutatravel.com/bus-on-rent-in-pune-for-goa-trip",
          "priceCurrency": "INR",
          "price": "4500",
          "priceValidUntil": "2024-12-31"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "reviewCount": "180"
        },
        "review": [
          {
            "@type": "Review",
            "author": {
              "@type": "Person",
              "name": "Rajesh Kumar"
            },
            "datePublished": "2024-07-10",
            "reviewBody": "We hired a 32-seater AC bus from Pune to Goa. The journey was comfortable, and the bus was very well maintained. Highly recommend Amruta Travels for group trips."
          },
          {
            "@type": "Review",
            "author": {
              "@type": "Person",
              "name": "Sonal Mehta"
            },
            "datePublished": "2024-08-05",
            "reviewBody": "Great experience hiring a tempo traveller for a corporate trip to Goa. On-time service and great comfort throughout the trip!"
          }
        ],
        "serviceArea": {
          "@type": "Place",
          "name": "Pune",
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": "18.5204",
            "longitude": "73.8567"
          }
        },
        "availableChannel": {
          "@type": "ServiceChannel",
          "serviceUrl": "https://amrutatravel.com/bus-on-rent-in-pune-for-goa-trip"
        },
        "keywords": "Pune to Goa Mini Bus on Rent, Pune to Goa Tempo Traveller on Rent, Pune to Goa Urbania on Rent, Pune to Goa 17 Seater on Rent, Pune to Goa 14 17 25 32 Seater on Rent, Pune to Goa 35 45 50 Seater on Rent, Pune to Goa Bus package on Rent, Hire Pune to Goa Mini Bus & Tempo Traveller on Rent, Hire Tempo Traveller Minibus, Bus on Rent in Pune, Pune to Goa bus Hire on Rent, Pune to Goa Ac Bus On Rent, Pune to Goa bus Rental Service, Hire a bus from Pune to Goa, Bus Rental Service Pune to Goa, Pune to Goa Holiday Bus Rental, Pune to Goa family Trip Bus Hire, Pune to Goa Weekend Getaway Bus Rental, Pune to Goa Corporate bus Hire, Pune to Goa Group Travel Bus Rental, AC bus Hire Pune to Goa, Comfortable Bus from Pune to Goa, 32 seater bus rental Pune to Goa, 50 seater bus hire from Pune to Goa, tempo traveller on rent in pune for corporate events, luxury tempo traveller on rent in pashan pune"
      }
    `}
  </script>
</Helmet>

<section className="page-header">
      <div className="page-header-shape"></div>
      <div className="container">
        <div className="page-header-info">
          <h4>{cardData.keyword}</h4>
          <h2>
            Feel your journey <br /> with <span>Amruta Travels</span>
          </h2>
          
        </div>
      </div>
    </section>

<section>
    <div className="container-fluid ">
<div className="row">
<div className="col-12 col-md-7">
    <img src='\img\Keyword Based Image\Keyword Based Image-37.jpg' alt='img'/>
<h3 className="py-1">{cardData.heading} </h3><p>{cardData.headingDescription}</p>
<div>
      {cardData.topPlaces.map((place, index) => (
        <div key={index}>
          <h3 className="py-1 Colo">{place.title}</h3>
          <p>{place.description}</p>
        </div>
      ))}
    </div>
    <div>
        {cardData.services.map((service, index) => (
          <div key={index} className=" my-4">
            <h3 className="py-1 Colo">{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

<table className="table table-responsive Border-key">
  <tbody className=' Border-key'>
    {cardData.tableData.map((row, rowIndex) => (
      <tr className='Border-key' key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className=' Border-key' >{cell}</td>
        ))}
      </tr>
    ))}
  </tbody>
</table>

   <div id="why-choose-section">
      {cardData.whychoose.map((item, index) => (
        <div key={index}>
          <h3 className="py-1 Colo">{item.WhyChooseheading}</h3>
          <p>{item.WhyChoosedescription}</p>
        </div>
      ))}
    </div>

   
<div>
      <h3 className='Colo'>FAQs for “Bus on Rent in Pune” – Amruta Travels:</h3>
      <Accordion data={faqData} />
    </div>

    <CustomCarousel items={carouselItems} options={carouselOptions} />

    <div>

      <div className="contact-box">
      <h2>{contactData.heading}</h2>
      <p>For booking inquiries or any assistance, please feel free to contact us:</p>
      <div className="contact-details">
        <p><strong className='Colo'>Mobile No:</strong></p>
        <ul>
          {contactData.contactNumbers.map((number, index) => (
            <li key={index}>
              <a href={`tel:${number}`} className="contact-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
        <p><strong className='Colo'>Email Id:</strong></p>
        <p>
          <a href={`mailto:${contactData.email}`} className="contact-link">
            {contactData.email}
          </a>
        </p>
      </div>
    </div>
    </div>
</div>

<div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

<div className=" rounded p-4 shadow-sm Uni_border" >
    <h4 className="pb-3 pt-3 lead fw-bold whit">Contact Information</h4>

    <div className="row ">
   
      <div className="col-12 mb-3">
        <div className="Small_border  rounded p-3 text-center ">
          <h4 className=" lead fw-semibold text-dark">Phone Numbers</h4>
          <i className="bi bi-telephone-fill fs-1 mb-2"></i>
          <div className=''>
            <a href="tel:+91 8421333435" className="d-block  whit">+91 8421333435</a>
            <a href="tel:+91 7875774499" className="d-block  whit">+91 7875774499</a>
            <a href="tel:+91 7620411277" className="d-block  whit">+91 7620411277</a>
            <a href="tel:+91 9284718314" className="d-block  whit">+91 9284718314</a>
          </div>
        </div>
      </div>

    
      <div className="col-12 mb-3 ">
        <div className="Small_border rounded p-3 text-center">
          <i className="bi bi-envelope fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Email</h4>
            <a href="mailto:booking@amrutatravel.com" className="whit d-block">
              booking@amrutatravel.com
            </a>
          </div>
        </div>
      </div>

      
      <div className="col-12 mb-3">
        <div className="Small_border  rounded p-3 text-center">
          <i className="bi bi-house-fill fs-1 mb-2"></i>
          <div>
            <h4 className=" fw-semibold lead">Address</h4>
            <p className="whit ">
              <i>
              Amruta Travels:<br />
              Office No.- 2, Ground Floor,<br />
              Wings H-(Puram), Prayeja City,<br />
              Phase-2, Near Prayeja City,<br />
              Vadgaon Budruk,<br />
              Pune, Maharashtra - 411041
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<FareCard/>

</div>
    </div>
</section>



        </div>
    );
}
export default BusOnRentInPune;